var _a;
import key from './key';
import loadable from '@loadable/component';
export default [
    {
        id: key.ID,
        reducer: function () { return import('./organization-management.reducer'); }
    },
    {
        point: '@@components',
        namespace: key.ID,
        onload: function () { return [{ key: 'OrganizationManagementView', component: function () { return import('./organization-management-view'); } }]; }
    },
    {
        point: '@@layers',
        prefix: '@organizationManagement',
        onload: function () { return import('./layers'); }
    },
    {
        resource: '@organization-management',
        value: (_a = {},
            _a['elements/organization-selector'] = loadable(function () { return import('./elements/organization-selector'); }),
            _a)
    }
];
